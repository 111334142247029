export const shareNestedPaths = {
  DRAWER_GUARANTEE: 'guarantee/:code',
  DRAWER_DETAILS_EXCLUSION: ':offerId/details',
};

const paths = {
  HUB: '/',
  HUB_QUOTE: '/:quoteId',
};

export default paths;
