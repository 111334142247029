import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as errors from '#shared/components/Error/translations';
import * as shared from '#shared/translations';
import * as companyProperties from '#shared/translations';

import { resources as resourcesApp } from '#app/i18n';
import * as ui from '#quote/components/UI/translations';
import * as funnel from '#quote/features/Funnel/translations';
import * as hub from '#quote/features/Hub/translations';

const resources = {
  fr: {
    ...resourcesApp.fr,
    companyProperties: companyProperties.fr,
    errors: errors.fr,
    funnel: funnel.fr,
    hub: hub.fr,
    shared: {
      ...resourcesApp.fr.shared,
      partner: shared.fr.partner,
    },
    ui: {
      ...ui.fr,
      ...resourcesApp.fr.ui,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong', 'b', 'br', 'i', 'ul', 'li', 'u'],
  },
  saveMissing: true,
  missingKeyHandler: (_lng, ns, key) => {
    console.error(`missing translation in namespace: '${ns}' with key '${key}'`);
  },
});

export default i18n;
