import healthcareFr from './healthcare.fr.json';
import hubFr from './hub.fr.json';
import matchingFr from './matching.fr.json';
import multiFr from './multi.fr.json';
import revenueLossFr from './revenue_loss.fr.json';
import riskFr from './risk.fr.json';

const fr = {
  ...hubFr,
  risk: riskFr,
  matching: matchingFr,
  multi: multiFr,
  healthcare: healthcareFr,
  revenue_loss: revenueLossFr,
};

export { fr };
