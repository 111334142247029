import * as Sentry from '@sentry/react';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '#shared/UI/Loader';
import Notification from '#shared/UI/Notification';
import DebugToolbar from '#shared/components/DebugToolbar';
import ConnectAsToolbar from '#shared/components/DebugToolbar/ConnectAsToolbar';
import Error404 from '#shared/components/Error/404';
import Error500 from '#shared/components/Error/500';
import ScrollToTop from '#shared/components/ScrollToTop';
import SharedPath from '#shared/constants/paths';
import { getEnvVar } from '#shared/envs';
import { HubSpotConversationsWidgetRefresh } from '#shared/utils/hubspot';
import { UtmToCookie } from '#shared/utils/utm';
import { WindowMessageHistorySync } from '#shared/utils/windowMessage';

import Path from '#quote/features/paths';

const Hub = lazy(() => import('#quote/features/Hub/pages'));
const MatchingIndex = lazy(() => import('#quote/features/Hub/pages/Matching'));
const CoachIndex = lazy(() => import('#quote/features/Hub/pages/Coach'));
const EndorsementIndex = lazy(() => import('#quote/features/Endorsement'));
const SubscriptionIndex = lazy(() => import('#quote/features/Funnel/pages'));
const HealthIndex = lazy(() => import('#quote/features/Hub/pages/Healthcare'));
const RevenueLossIndex = lazy(() => import('#quote/features/Hub/pages/RevenueLoss'));

const Router: React.FC = () => {
  return (
    <BrowserRouter basename={getEnvVar('VITE_BASE_NAME')}>
      <Notification />
      <Suspense fallback={<Loader />}>
        <DebugToolbar />
        <ConnectAsToolbar />
        <ScrollToTop />
        <HubSpotConversationsWidgetRefresh />
        <WindowMessageHistorySync />
        <UtmToCookie />
        <Routes>
          <Route path={SharedPath.NOT_FOUND} element={<Error404 />} />
          <Route path={Path.HUB_QUOTE} element={<Hub />} />
          <Route path={`${Path.HUB}`} element={<Hub />} />
          <Route path={`${SharedPath.MATCHING}/*`} element={<MatchingIndex />} />
          <Route path={`${SharedPath.ENDORSEMENT}/*`} element={<EndorsementIndex />} />
          <Route path={`${SharedPath.COACH}/*`} element={<CoachIndex />} />
          <Route path={`${SharedPath.SUBSCRIPTION}/*`} element={<SubscriptionIndex />} />
          <Route path={`${SharedPath.HEALTHCARE}/*`} element={<HealthIndex />} />
          <Route path={`${SharedPath.REVENUE_LOSS}/*`} element={<RevenueLossIndex />} />
          <Route path="*" element={<Navigate to={SharedPath.NOT_FOUND} replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Sentry.withErrorBoundary(Router, {
  fallback: () => <Error500 />,
  showDialog: true,
});
