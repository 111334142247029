import tablePlaceholderFr from '#shared/UI/TablePlaceholder/translations/fr.json';
import contactFr from '#shared/components/Contact/translations/fr.json';

import sirenInputFr from '#quote/components/SirenInputSearch/translations/fr.json';
import basketFr from '#quote/components/UI/Basket/translations/fr.json';
import containerQuestion from '#quote/components/UI/ContainerQuestion/translations/fr.json';

const fr = {
  containerQuestion: containerQuestion,
  basket: basketFr,
  sirenInput: sirenInputFr,
  contact: contactFr,
  tablePlaceholder: tablePlaceholderFr,
};

export { fr };
